<template>
  <div class="entIn">
    <img
      class="banner"
      src="https://oss.pinmallzj.com/image/maintain/2023/08/10/Framex5722-15vjfir2t5no.png"
      alt=""
    />
    <div class="stepBox wrapper_1200">
      <div class="title">企业入驻流程</div>
      <div class="stepLvBox acea-row row-middle row-between">
        <div
          class="stepItem acea-row row-top pointer"
          v-for="(item, index) in stepList"
          :key="index"
        >
          <div class="stepItemBox acea-row row-column row-start">
            <img :src="item.pic" alt="" />
            <div class="u-mt30">{{ item.name }}</div>
          </div>
          <img
            v-if="index + 1 < stepList.length"
            src="@/assets/newImgs/Union1.png"
            alt=""
            class="u-ml40 arrow"
          />
        </div>
      </div>
    </div>
    <div class="entBox wrapper_1200">
      <div
        class="entItem pointer acea-row row-between"
        v-for="(item, index) in entList"
        :key="index"
      >
        <img :src="item.pic" class="entImg" alt="" />
        <div class="rightBox">
          <div class="title">
            <span class="name">{{ item.name }}</span>
            <span class="subName">{{ item.subName }}</span>
          </div>
          <div class="info u-mt26">
            扫描入驻二维码可进行入驻申请、扫描小程序二维码直接打开小程序
          </div>
          <div class="imgBox u-mt26 acea-row row-middle">
            <div class="leftBox">
              <div class="u-mb12 name">入驻申请</div>
              <div class="qrBox acea-row row-center-wrapper">
                <img :src="item.applyUrl" alt="" />
              </div>
            </div>
            <div class="leftBox u-ml66">
              <div class="u-mb12 name">小程序二维码</div>
              <div class="qrBox acea-row row-center-wrapper">
                <img :src="item.appUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sendVerifyCode from "@/mixins/SendVerifyCode";
import { entSettled, getCodeApi } from "@/api/user.js";
export default {
  name: "entIn",
  mixins: [],
  data() {
    return {
      stepList: [
        {
          name: "识别二维码",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5661-66m6t9mhnszv.png",
        },
        {
          name: "进入公众号或小程序邀请页面",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5662-q7wcc36yz2ie.png",
        },
        {
          name: "填写资料提交信息并等待审核",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5663-nhm4tzo606wd.png",
        },
        {
          name: "审核完成",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5664-9e8jqzbqk8pz.png",
        },
        {
          name: "完成入驻",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/09/08/Framex5665-3ydusv37fgw0.png",
        },
      ],
      entList: [
        {
          name: "聚如SaaS平台",
          subName: "（礼品服务商版本）",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14142-ihkyhq6jr19h.png",
          applyUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/JRapply-ojtavr19nsa5.png",
          appUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/jr-uofd4ummoxn1.png",
        },
        {
          name: "品聚SaaS平台",
          subName: "（供应商版本）",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14145-109ugrwntnq0.png",
          applyUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/PJapply-3d5xxpg703lr.png",
          appUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/pj-axyfpo4syvcd.png",
        },
        {
          name: "政企通SaaS平台",
          subName: "（终端客户版本）",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14147-tvgp4253o718.png",
          applyUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/ZQTapply-b0ebiw6xifml.png",
          appUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/zqt-fnd19vrvf5gf.png",
        },
        {
          name: "蔚美SaaS平台",
          subName: "",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14149-bvjvipbvxd2m.png",
          applyUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/WMapply-vtp7wufagnww.png",
          appUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/wm-zlbyf9pvo61r.png",
        },
        {
          name: "暖点SaaS平台",
          subName: "",
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/11/Rectanglex14150-hj9bmdtcapt3.png",
          applyUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/NDapply-xb2ht9mrbw77.png",
          appUrl:
            "https://oss.pinmallzj.com/image/maintain/2023/08/11/nd-zzye3jpxxiz9.png",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.entIn {
  background: #fff;
  padding-top: 91px;
}
.banner {
  width: 100%;
  min-height: 500px;
}
.stepBox {
  padding-top: 160px;
  .title {
    color: #333a45;
    text-align: center;
    font-family: PingFang SC;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .stepLvBox {
    margin-top: 114px;
    width: 100%;
    .stepItem {
      height: 218px;
    }
    .stepItemBox {
      img {
        width: 130px;
        height: 140px;
      }
      div {
        text-align: center;
        color: #333;
        text-align: center;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 144px;
      }
    }
    .arrow {
      margin-top: 70px;
      width: 30px;
      height: 15px;
    }
  }
}

.entBox {
  margin-top: 130px;
  .entItem {
    height: 418px;
    padding: 33px 98px 33px 60px;
    margin-bottom: 60px;
    border-radius: 4px;
    border: 2px solid #fff;
    transition: all 0.3s linear;
    background: linear-gradient(180deg, #f3f4f7 0%, #fff 100%, #fff 100%);

    .entImg {
      width: 481px;
      height: 352px;
      border-radius: 2px;
    }
    .rightBox {
      .title {
        position: relative;
        padding-left: 14px;
        .name {
          color: #16181a;
          font-family: PingFang SC;
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
        }
        .subName {
          color: #16181a;
          font-family: PingFang SC;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
        }
        &::before {
          content: "";
          background: #3266f6;
          width: 7px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .imgBox {
        .leftBox {
          .name {
            color: #505968;
            font-family: PingFang SC;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .qrBox {
            padding: 4px;
            background: #fff;
            border-radius: 6px;
            border: 4px solid #fff;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #fff 100%
            );
            box-shadow: 1.7860082387924194px 3.572016477584839px
              27.683128356933594px 0px rgba(166, 174, 194, 0.5);
          }
          img {
            width: 180px;
            height: 180px;
          }
        }
      }
    }
  }
  .entItem:hover {
    box-shadow: 0.7044028639793396px 7px 11.270445823669434px 1px
        rgba(232, 236, 243, 0.8),
      3.5220143795013428px 4.226417064666748px 7.748431205749512px 0px
        rgba(255, 255, 255, 0.25) inset;
  }
}
</style>
